<template>
  <octo-table
    :enable-store="false"
    :action="endpoints.DATATABLES.reports"
    :fields="fields"
    :filters="filters"
    name="ReportDataTable"
    @onResetFilters="resetFilters"
    :key="`datatable-${datatableKey}`"
  >
    <template v-slot:name="data">
      <div class="text-truncate">{{ $t('reports.types.' + data.row.name) }}</div>
    </template>

    <template v-slot:dateStart="data">
      {{ data.row.date_from | date }}
    </template>

    <template v-slot:date="data">
      {{ data.row.updated_at | datetime }}
    </template>

    <template v-slot:dateEnd="data">
      {{ data.row.date_to | date }}
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
          <base-button class="mx-1"
                       link
                       size="sm"
                       icon
                       @click="exportReport(data.row)">
            <octo-icon icon="download"/>
          </base-button>

          <base-button
            class="mx-1"
            @click="$router.push({name: 'reports.show', params: {id : data.row.id}})"
            link
            size="sm"
            icon
          >
            <octo-icon icon="right-arrow"/>
          </base-button>

          <base-button
            class="mx-1"
            @click="deleteReport(data.row.id)"
            link
            size="sm"
            icon
            confirm-required
            :confirm-text="$t('confirm.delete_report')"
          >
            <octo-icon icon="wrong"/>
          </base-button>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import {mapActions} from "vuex";

export default {
  name: "ReportDatatable",
  components: {OctoIcon, OctoTable},
  data() {
    return {
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'dateStart', label: 'date_start', width:"150", slot: true, align: 'center'},
        {prop: 'dateEnd', label: 'date_end', width:"150",slot: true, align: 'center'},
        {prop: 'name', label: 'name', slot: true, align: 'left'},
        {prop: 'date', label: 'created', width:"150",slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', width:"150",slot: true, align: 'center'},
      ],
      datatableKey: 0
    }
  },
  methods: {
    ...mapActions({
      downloadFile: 'common/downloadFile'
    }),

      resetFilters: function () {
        this.filters = {

        };
      },
    exportReport: function (report) {
      this.downloadFile({
        url: endpoints.REPORTS_EXPORT.replace('{id}', report.id),
        filename: 'Report-' + report.name + '-' + report.date_from + '_' + report.date_to + '.xlsx',
        method: 'get',
      }).then()
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.export_error')});
        })
    },

    deleteReport(id) {
      this.$api.delete(endpoints.REPORTS_DELETE.replace('{id}', id))
        .then(() => {
          this.datatableKey++;
          this.$notify({type: 'success', message: this.$t('notifications.delete_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.delete_error')});
        })
    }
  }
}
</script>

<style scoped>

</style>

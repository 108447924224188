<template>
  <div class="row">
    <div class="col-12">
      <report-datatable :key="reportDatatableKey"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ReportDatatable from "./datatables/ReportDatatable";

export default {
  name: "IndexPage",
  components: {ReportDatatable},
  data() {
    return {
      reportDatatableKey:1
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.$echo.leave(`report-result.${this.currentUser.id}`);

    this.$echo
      .private(`report-result.${this.currentUser.id}`)
      .listen('.report-result', (e) => {
        this.reportDatatableKey++;
        this.$notify({
          type: e.success ? 'success' : 'danger',
          message: this.$t(`notifications.job_name.${e.name}`) + ' ' +
            (e.success
                ? this.$t('notifications.report_generated')
                : this.$t('notifications.report_not_generated')
            )
        });
      });
  },
  destroyed() {
    this.$echo.leave(`report-result.${this.currentUser.id}`);
  }
}
</script>

<style scoped>

</style>
